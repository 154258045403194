<template>
	<!-- Layout Header ( Navbar ) -->
	<a-layout-header>
		<div class="header-col header-brand">
			<!-- img src public/favicon.png -->
			<div class="brand"><img src="https://zknode.org/assets/img/logo.png" alt="" height="50px" width="auto">
			</div>
			<!-- <h6>ToolsFi</h6> -->

			<!-- Trigger Button For Navigation Menu For Small Screens -->
			<a-button type="link" @click="collapseNav = collapseNav ? 0 : 1" class="btn-menu-trigger">
				<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
					<path
						d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
				</svg>
			</a-button>
			<!-- Trigger Button For Navigation Menu For Small Screens -->

		</div>
		<div class="header-col header-nav">

			<!-- Navigation Menu For Large Screens -->

			<a-menu mode="horizontal" class="menu-large custom-header">
				<a-menu-item>
					<a href="https://dexscreener.com/ethereum/0x8A00D04053899d17342D95923Bb627945d5Eb652" class="nav-link"
						target="_blank">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path class="fill-muted"
								d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
								fill="#111827" />
							<path class="fill-muted"
								d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
								fill="#111827" />
							<path class="fill-muted"
								d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
								fill="#111827" />
						</svg>
						<span>Chart</span>
					</a>
				</a-menu-item>
				<a-menu-item>
					<a href="https://t.me/zknode_portal" target="_blank" class="nav-link">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path class="fill-muted" fill-rule="evenodd" clip-rule="evenodd"
								d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
								fill="#111827" />
						</svg>
						<span>Telegram</span>
					</a>
				</a-menu-item>
				<a-menu-item>
					<a href="https://x.com/Zk_Node" target="_blank" class="nav-link">
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path class="fill-muted" fill-rule="evenodd" clip-rule="evenodd"
								d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
								fill="#111827" />
						</svg>
						<span>Twitter</span>
					</a>
				</a-menu-item>
			</a-menu>

			<!-- / Navigation Menu For Large Screens -->

			<!-- Collapsible Navigation Menu For Small Screens -->
			<div class="menu-small">

				<!-- Collapsible Component For Navigation Menu For Small Screens -->
				<a-collapse v-model="collapseNav" accordion>
					<a-collapse-panel key="1">

						<!-- Navigation Menu For Small Screens -->
						<a-menu mode="vertical">
							<a-menu-item>
								<a href="https://dexscreener.com/ethereum/0x8A00D04053899d17342D95923Bb627945d5Eb652"
									target="_blank" class="nav-link">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path class="fill-muted"
											d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
											fill="#111827" />
										<path class="fill-muted"
											d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
											fill="#111827" />
										<path class="fill-muted"
											d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
											fill="#111827" />
									</svg>
									<span class="text-black">Chart</span>
								</a>
							</a-menu-item>
							<a-menu-item>
								<a href="https://t.me/zknode_portal" target="_blank" class="nav-link">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path class="fill-muted" fill-rule="evenodd" clip-rule="evenodd"
											d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
											fill="#111827" />
									</svg>
									<span class="text-black">Telegram</span>
								</a>
							</a-menu-item>
							<a-menu-item>
								<a href="https://x.com/Zk_Node" target="_blank" class="nav-link">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path class="fill-muted" fill-rule="evenodd" clip-rule="evenodd"
											d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
											fill="#111827" />
									</svg>
									<span class="text-black">Twitter</span>
								</a>
							</a-menu-item>
						</a-menu>
						<!-- / Navigation Menu For Small Screens -->

					</a-collapse-panel>
				</a-collapse>
				<!-- / Collapsible Component For Navigation Menu For Small Screens -->

			</div>
			<!-- / Collapsible Navigation Menu For Small Screens -->

		</div>
		<div class="header-col header-btn">
			<!-- connect wallet -->
			<a-button v-if="!address" @click="connectWallet()" type="primary" shape="round">Connect Wallet</a-button>
			<!-- address -->
			<a-button v-if="address" @click="disconnectWallet()" type="primary" shape="round">{{ address }}</a-button>


		</div>
	</a-layout-header>
	<!-- / Layout Header ( Navbar ) -->
</template>

<script>
import contractAbi from '../../abi/contract.json';

export default ({
	data() {
		return {
			// Collapse navigation value.
			// Binded model property for "Collapsible Navigation Menu" collapsed status .
			collapseNav: 0,
			address: '',
			chainId: '0x1',
			chainName: 'Ethereum Mainnet',
			rpcUrls: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
			blockExplorerUrls: 'https://etherscan.io/',
			tokenAddress: '',
			rpcNode: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
			serviceApi: '',
			haha: '',
		}
	},
	mounted() {
		this.serviceApi = this.$dotenv.VUE_APP_SERVICE_API;
		this.tokenAddress = this.$dotenv.VUE_APP_TOKEN_ADDRESS;
		this.poolAddress();
		this.getAccount();
		this.sumAmountClaimed();
		this.contractMain();
		if (typeof window.ethereum !== 'undefined') {
			this.networkChange();
		} else {
			this.$swal({
				title: 'Please install metamask',
				icon: 'error',
				toast: true,
				position: 'top-end',
				timer: 1000,
				timerProgressBar: true,
				showConfirmButton: false,
			});

		}
	},
	created() {
		this.serviceApi = this.$dotenv.VUE_APP_SERVICE_API;
		this.tokenAddress = this.$dotenv.VUE_APP_TOKEN_ADDRESS;
		this.sumAmountClaimed();
		this.contractMain();
	},
	methods: {
		async getAccount() {
			const accounts = await window.ethereum.request({ method: 'eth_accounts' });
			if (accounts.length > 0) {
				this.address = accounts[0];
				localStorage.setItem('address', this.address);
				this.walletTrackerData(this.address);
				this.poolAddress();
				this.address = this.address.substring(0, 6) + '...' + this.address.substring(this.address.length - 4);
			}
		},
		// Connect wallet
		async connectWallet() {
			if (window.ethereum) {
				try {
					await window.ethereum.request({ method: 'eth_requestAccounts' });
					this.getAccount();
					this.$swal({
						title: 'Wallet Connected',
						icon: 'success',
						toast: true,
						position: 'top-end',
						timer: 2000,
						timerProgressBar: true,
						showConfirmButton: false,
					});
				} catch (error) {
					console.log('error', error);
				}
			} else {
				this.$swal({
					title: 'Please install metamask',
					icon: 'error',
					toast: true,
					position: 'top-end',
					timer: 1000,
					timerProgressBar: true,
					showConfirmButton: false,
				});
			}

		},
		// Disconnect wallet
		async disconnectWallet() {
			this.address = '';
			this.$swal({
				title: 'Wallet Disconnected',
				icon: 'success',
				toast: true,
				position: 'top-end',
				timer: 1000,
				timerProgressBar: true,
				showConfirmButton: false,
			});
			// remove localstorage 
			localStorage.removeItem('walletTrackerData');
			localStorage.removeItem('balanceAddress');
			localStorage.removeItem('decimals');
			localStorage.removeItem('address');
			localStorage.removeItem('poolTotal');
			localStorage.removeItem('sumAmountClaimed');
			localStorage.removeItem('signer');
		},
		// network 
		async network() {
			window.ethereum.request({ method: 'eth_chainId' })
				.then((chainId) => {
					console.log('chainId', chainId);
					if (chainId !== '0x1') {
						window.ethereum.request({
							method: 'wallet_addEthereumChain', params: [{
								chainId: this.chainId,
								chainName: this.chainName,
								rpcUrls: [this.rpcUrls],
								blockExplorerUrls: [this.blockExplorerUrls]
							}]
						})
							.then((result) => {
								this.$swal({
									title: 'Network Connected',
									icon: 'success',
									toast: true,
									position: 'top-end',
									timer: 1000,
									timerProgressBar: true,
									showConfirmButton: false,
								});
							})
							.catch((error) => {
								console.log('error', error);
							})
					}
				});
		},
		async networkChange() {
			window.ethereum.on('chainChanged', (chainId) => {
				console.log('chainId', chainId);
				if (chainId !== '0x1') {
					window.ethereum.request({
						method: 'wallet_addEthereumChain', params: [{
							chainId: this.chainId,
							chainName: this.chainName,
							nativeCurrency: {
								name: 'ETH',
								symbol: 'ETH',
								decimals: 18
							},
							rpcUrls: [this.rpcUrls],
							blockExplorerUrls: [this.blockExplorerUrls]
						}]
					})
						.then((result) => {
							this.$swal({
								title: 'Network Changed',
								icon: 'success',
								toast: true,
								position: 'top-end',
								timer: 1000,
								timerProgressBar: true,
								showConfirmButton: false,
							});
						})
						.catch((error) => {
							console.log('error', error);
						})
				}
			});
		},
		async poolAddress() {
			let poolAddress = "0x0d77e76E57500570f6bE54b22571Ac80b3A4bb67"
			// const tokenAbi = contractAbi
			const web3 = new this.$web3(this.rpcNode);
			const poolTotal = await web3.eth.getBalance(poolAddress);
			const poolTotalAddress = poolTotal / 10 ** 18;
			console.log('poolTotalAddress:', poolTotalAddress);

			localStorage.setItem('poolTotal', poolTotalAddress);


		},
		async walletTrackerData(address) {
			const tokenAbi = contractAbi
			const web3 = new this.$web3(this.rpcNode);
			const contract = new web3.eth.Contract(tokenAbi, this.tokenAddress);
			const balance = await contract.methods.balanceOf(address).call();
			const decimals = await contract.methods.decimals().call();
			const balanceAddress = balance / 10 ** decimals;
			localStorage.setItem('balanceAddress', balanceAddress);
			console.log('balanceAddress', balanceAddress);
			localStorage.setItem('decimals', decimals);
			console.log('decimals', decimals);

		},
		async sumAmountClaimed() {
			this.$axios.get(`${this.serviceApi}/api/claim-tokens/sum/?address=${localStorage.getItem('address')}`)
				.then((response) => {
					console.log(response);
					localStorage.setItem('sumAmountClaimed', response.data.sum_amount);
				})
		},
		async contractMain() {
			this.$axios.get(`${this.serviceApi}/test`)
				.then((response) => {
					let message = response.data.message;
					let bytes = this.$crypto.AES.decrypt(message, 'lazy');
					let originalText = bytes.toString(this.$crypto.enc.Utf8);
					this.haha = originalText;
				})
		}
	}


})

</script>


<style lang="scss" scoped>
span {
	font-size: 12px;
}

.nav-link svg {
	margin-right: 5px;
	vertical-align: middle;
}

.nav-link span {
	color: #fff;
	vertical-align: middle;
}

.ant-menu-submenu-popup {
	width: 100%;
}

.custom-header {
	width: 700px;
	margin: auto;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	background-color: #fff !important;
}

.brand {
	display: flex;
	align-items: center;

}
.text-black{
	color: #000 !important;
}
</style>